/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */
import { AukWindow } from '@shared/model/auk-window.interface';

/***************************************************************************************************
 * BROWSER POLYFILLS
 /***************************************************************************************************

 * Zone JS is required by Angular itself.
 */
const WINDOW: AukWindow = window;

// disable zone js monkey patching timers
WINDOW.__Zone_disable_timers = true;

import 'zone.js'; // Included with Angular CLI.

// Could fix a white screen bug on iOS
import 'globalthis/auto';

// fix error with animations on iPhone
if (!('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))) {
  void import('web-animations-js');
}

/***************************************************************************************************
 * APPLICATION FIXES
 */
(window as any).global = window; // ng2-dragula package
